<template>
    <div v-frag>
        <div class="row" style="padding-bottom:60px">
            <div class="col-md-2 hidden-sm hidden-xs"></div>
            <div class="col-md-8 col-sm-12">
                <div class="welcome">
                    <h4>{{ $t('responsible-gaming.responsible-gaming') }}</h4>

                    <button class="terms-accordion"  @click="toggleAccordian">{{ $t('responsible-gaming.part-one') }}</button>
                    <div class="terms-conditions">
                        <div class="terms-body">
                            <p style="padding: 10px;">{{ $t('responsible-gaming.part-one-details') }}</p>
                        </div>
                    </div>

                    <button class="terms-accordion"  @click="toggleAccordian">{{ $t('responsible-gaming.part-two') }}</button>
                    <div class="terms-conditions">
                        <div class="terms-body">
                            <p>{{ $t('responsible-gaming.part-two-details') }}</p>
                            <ul style="list-style: lower-alpha;">
                                <li>{{ $t('responsible-gaming.part-two-details-1') }}</li>
                                <li>{{ $t('responsible-gaming.part-two-details-2') }}</li>
                                <li>{{ $t('responsible-gaming.part-two-details-3') }}</li>
                            </ul>
                            <p>{{ $t('responsible-gaming.part-two-details-4') }}</p>
                        </div>
                    </div>

                    <button class="terms-accordion"  @click="toggleAccordian">{{ $t('responsible-gaming.part-three') }}</button>
                    <div class="terms-conditions">
                        <div class="terms-body">
                            <p>{{ $t('responsible-gaming.part-three-details') }}</p>
                        </div>
                    </div>

                    <button class="terms-accordion"  @click="toggleAccordian">{{ $t('responsible-gaming.part-four') }}</button>
                    <div class="terms-conditions">
                        <div class="terms-body">
                            <p>{{ $t('responsible-gaming.part-four-details') }}</p>
                            <ul style="list-style: lower-alpha;">
                                <li>{{ $t('responsible-gaming.part-four-details-1') }}</li>
                                <li>{{ $t('responsible-gaming.part-four-details-2') }}</li>
                                <li>{{ $t('responsible-gaming.part-four-details-3') }}</li>
                                <li>{{ $t('responsible-gaming.part-four-details-4') }}</li>
                                <li>{{ $t('responsible-gaming.part-four-details-5') }}</li>
                            </ul>
                        </div>
                    </div>

                    <button class="terms-accordion"  @click="toggleAccordian">{{ $t('responsible-gaming.part-five') }}</button>
                    <div class="terms-conditions">
                        <div class="terms-body">
                            <p>{{ $t('responsible-gaming.part-five-details') }}</p>
                            <p>{{ $t('responsible-gaming.part-five-details-1') }}</p>
                        </div>
                    </div>

                    <button class="terms-accordion"  @click="toggleAccordian">{{ $t('responsible-gaming.part-six') }}</button>
                    <div class="terms-conditions">
                        <div class="terms-body">
                            <p>{{ $t('responsible-gaming.part-six-details') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
export default {
  name: 'responsible-gaming',
  methods : {
    toggleAccordian: function(event) {
      var element = event.target;
      element.classList.toggle("active");
          
      var panel = element.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    }
  }
}
</script>

<style scoped>

</style>